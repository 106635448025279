import {
  Checkbox,
  Table,
  TableColumn,
  useModal,
} from '@keymax-dev/smartepi-ui';
import React from 'react';
import ListPageSearchBar from '../../components/list-page-search-bar';
import PageActions from '../../components/page-actions';
import PageTitle from '../../components/page-title';
import useListPage from '../../hooks/use-list-page';
import { EndPoints, RouterPaths } from '../../utils/constants';
import AddEmployeeModal from './add-employee';
import { EmployeesPageElement, EmployeesTableRowElement } from './style';
import { User } from '../../domain/models';

const Strings = {
  Buttons: {
    AddEmployee: 'Adicionar Funcionário',
  },
  Inputs: {
    Search: 'Buscar funcionário...',
  },
  Title: 'Funcionários',
  Table: {
    ColumnTitles: {
      Selection: 'Seleção',
      Employee: 'Funcionário',
      Identification: 'ID',
    },
  },
  Messages: {
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo deletar os setores selecionados?',
  },
};

export default function EmployeesPage(): JSX.Element {
  // Hooks
  const listPage = useListPage<User.Data>(
    EndPoints.Users,
    RouterPaths.Employee
  );
  const addEmployeeModal = useModal(<AddEmployeeModal />, {
    containerProps: { style: { padding: 0 } },
  });

  // Handlers
  const addEmployeeHandler = (): void => {
    addEmployeeModal.onClose(listPage.addItemModalCloseHandler);
    addEmployeeModal.open();
  };

  // Component Elements
  const pageActions = [
    {
      text: Strings.Buttons.AddEmployee,
      icon: 'plus',
      onClick: addEmployeeHandler,
    },
  ];
  const tableConfig = {
    rowEvents: listPage.rowEvents,
    rowProps: listPage.rowProps,
    onScroll: listPage.tableScrollHandler,
  };

  return (
    <EmployeesPageElement>
      <PageTitle>{Strings.Title}</PageTitle>
      <PageActions actions={pageActions} />
      <ListPageSearchBar
        listPage={listPage}
        strings={{
          placeholder: Strings.Inputs.Search,
          deleteTitle: Strings.Messages.Caution,
          deleteMessage: Strings.Messages.DeleteConfirmation,
        }}
      />
      <Table
        data={listPage.restList.list}
        config={tableConfig}
        loading={listPage.restList.loading}
      >
        {listPage.selectable && (
          <TableColumn
            name={Strings.Table.ColumnTitles.Selection}
            key="selected"
            flex={0}
            minwidth="120px"
          >
            {(item: User.Data): JSX.Element => (
              <div style={{ position: 'absolute' }}>
                <Checkbox
                  size="25px"
                  value={listPage.selectableList.keys.has(`${item._id}`)}
                />
              </div>
            )}
          </TableColumn>
        )}
        <TableColumn
          name={Strings.Table.ColumnTitles.Identification}
          key="id"
          maxwidth="100px"
          minwidth="100px"
        >
          {(item: User.Data): JSX.Element => (
            <EmployeesTableRowElement
              active={listPage.activeDetails.item?._id === item._id}
            >
              {item.identification || '-'}
            </EmployeesTableRowElement>
          )}
        </TableColumn>
        <TableColumn
          name={Strings.Table.ColumnTitles.Employee}
          key="employee"
          flex={1}
          minwidth="220px"
        >
          {(item: User.Data): JSX.Element => (
            <EmployeesTableRowElement
              active={listPage.activeDetails.item?._id === item._id}
            >
              {item.name}
            </EmployeesTableRowElement>
          )}
        </TableColumn>
      </Table>
    </EmployeesPageElement>
  );
}
