import {
  Checkbox,
  Table,
  TableColumn,
  useModal,
} from '@keymax-dev/smartepi-ui';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ListPageSearchBar from '../../components/list-page-search-bar';
import PageActions from '../../components/page-actions';
import PageTitle from '../../components/page-title';
import useListPage from '../../hooks/use-list-page';
import ActiveDetailsActions from '../../redux/actions/active-details-actions';
import { EndPoints, RouterPaths } from '../../utils/constants';
import { Role } from '../../domain/models';
import AddRoleModal from './add-role/index';
import { RolesPageElement, RolesTableRowElement } from './style';

const Strings = {
  Buttons: {
    AddRole: 'Adicionar Função',
  },
  Inputs: {
    Search: 'Buscar função...',
  },
  Title: 'Funções',
  Table: {
    ColumnTitles: {
      Selection: 'Seleção',
      Role: 'Função',
    },
  },
  Messages: {
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo deletar as funções selecionadas?',
  },
};

export default function RolesPage(): JSX.Element {
  // Hooks
  const listPage = useListPage<Role.Data>(EndPoints.Roles, RouterPaths.Role);
  const history = useHistory();
  const addRoleModal = useModal(<AddRoleModal />);
  const dispatch = useDispatch();

  const addRoleHandler = (): void => {
    addRoleModal.onClose((reason) => {
      if (typeof reason === 'object') {
        const newRole = reason as Role.Data;
        listPage.restList.updateItems([newRole]);
        dispatch(ActiveDetailsActions.setActiveDetails(newRole, dispatch));
        history.push(RouterPaths.Role(newRole._id));
      }
    });
    addRoleModal.open();
  };

  // Component Elements
  const pageActions = [
    {
      text: Strings.Buttons.AddRole,
      icon: 'plus',
      onClick: addRoleHandler,
    },
  ];
  const tableConfig = {
    rowEvents: listPage.rowEvents,
    rowProps: listPage.rowProps,
    onScroll: listPage.tableScrollHandler,
  };

  return (
    <RolesPageElement>
      <PageTitle>{Strings.Title}</PageTitle>
      <PageActions actions={pageActions} />
      <ListPageSearchBar
        listPage={listPage}
        strings={{
          placeholder: Strings.Inputs.Search,
          deleteTitle: Strings.Messages.Caution,
          deleteMessage: Strings.Messages.DeleteConfirmation,
        }}
      />
      <Table
        data={listPage.restList.list}
        config={tableConfig}
        loading={listPage.restList.loading}
      >
        {listPage.selectable && (
          <TableColumn
            name={Strings.Table.ColumnTitles.Selection}
            key="selected"
            flex={0}
            minwidth="120px"
          >
            {(item: Role.Data): JSX.Element => (
              <div style={{ position: 'absolute' }}>
                <Checkbox
                  size="25px"
                  value={listPage.selectableList.keys.has(`${item._id}`)}
                />
              </div>
            )}
          </TableColumn>
        )}
        <TableColumn
          name={Strings.Table.ColumnTitles.Role}
          key="role"
          flex={1}
          minwidth="320px"
        >
          {(item: Role.Data): JSX.Element => (
            <RolesTableRowElement
              active={listPage.activeDetails.item?._id === item._id}
            >
              {item.name}
            </RolesTableRowElement>
          )}
        </TableColumn>
      </Table>
    </RolesPageElement>
  );
}
