import { Trigger } from '@keymax-dev/smartepi-sdk';

export type TriggerType = {
  name: string;

  type: Trigger.Type;
};

export const TriggerTypes: TriggerType[] = [
  // {
  //   name: 'Licença com estoque vazio',
  //   type: Trigger.Type.LICENSE_WAREHOUSE_EMPTY,
  // },
  // { name: 'Licença offline', type: Trigger.Type.LICENSE_OFFLINE },
  // {
  //   name: 'Máquina com esteira bloqueada',
  //   type: Trigger.Type.MACHINE_WAREHOUSE_BLOCK,
  // },
  // {
  //   name: 'Máquina com estoque vazio',
  //   type: Trigger.Type.MACHINE_WAREHOUSE_EMPTY,
  // },
  // { name: 'Máquina offline', type: Trigger.Type.MACHINE_OFFLINE },
  { name: 'Erro de dispensa', type: Trigger.Type.WITHDRAWAL_ERROR },
  { name: 'Produto expirado', type: Trigger.Type.PRODUCT_EXPIRED },
];

export const findTriggerType = (type: Trigger.Type): TriggerType => {
  const triggerType = TriggerTypes.find((trigger) => trigger.type === type);

  if (triggerType) {
    return triggerType;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return { name: 'Desconhecido', type: null as any };
};
