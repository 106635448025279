import {
  Checkbox,
  Table,
  TableColumn,
  useModal,
} from '@keymax-dev/smartepi-ui';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ListPageSearchBar from '../../components/list-page-search-bar';
import PageActions from '../../components/page-actions';
import PageTitle from '../../components/page-title';
import useListPage from '../../hooks/use-list-page';
import ActiveDetailsActions from '../../redux/actions/active-details-actions';
import { EndPoints, RouterPaths } from '../../utils/constants';
import AddSectorModal from './add-sector';
import { SectorsPageElement, SectorsTableRowElement } from './style';
import { Sector } from '../../domain/models';

const Strings = {
  Buttons: {
    AddSector: 'Adicionar Setor',
  },
  Inputs: {
    Search: 'Buscar setor...',
  },
  Title: 'Setores',
  Table: {
    ColumnTitles: {
      Selection: 'Seleção',
      Sector: 'Setor',
    },
  },
  Messages: {
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo deletar os setores selecionados?',
  },
};

export default function SectorsPage(): JSX.Element {
  // Hooks
  const listPage = useListPage<Sector.Data>(
    EndPoints.Sectors,
    RouterPaths.Sector
  );
  const addSectorModal = useModal(<AddSectorModal />);

  const history = useHistory();
  const dispatch = useDispatch();

  // Handlers
  const addSectorHandler = (): void => {
    addSectorModal.onClose((reason) => {
      if (typeof reason === 'object') {
        const newSector = reason as Sector.Data;
        listPage.restList.updateItems([newSector]);
        dispatch(ActiveDetailsActions.setActiveDetails(newSector, dispatch));
        history.push(RouterPaths.Sector(newSector._id));
      }
    });
    addSectorModal.open();
  };

  // Component Elements
  const pageActions = [
    {
      text: Strings.Buttons.AddSector,
      icon: 'plus',
      onClick: addSectorHandler,
    },
  ];
  const tableConfig = {
    rowEvents: listPage.rowEvents,
    rowProps: listPage.rowProps,
    onScroll: listPage.tableScrollHandler,
  };

  return (
    <SectorsPageElement>
      <PageTitle>{Strings.Title}</PageTitle>
      <PageActions actions={pageActions} />
      <ListPageSearchBar
        listPage={listPage}
        strings={{
          placeholder: Strings.Inputs.Search,
          deleteTitle: Strings.Messages.Caution,
          deleteMessage: Strings.Messages.DeleteConfirmation,
        }}
      />
      <Table
        data={listPage.restList.list}
        config={tableConfig}
        loading={listPage.restList.loading}
      >
        {listPage.selectable && (
          <TableColumn
            name={Strings.Table.ColumnTitles.Selection}
            key="selected"
            flex={0}
            minwidth="120px"
          >
            {(item: Sector.Data): JSX.Element => (
              <div style={{ position: 'absolute' }}>
                <Checkbox
                  size="25px"
                  value={listPage.selectableList.keys.has(`${item._id}`)}
                />
              </div>
            )}
          </TableColumn>
        )}
        <TableColumn
          name={Strings.Table.ColumnTitles.Sector}
          key="sector"
          flex={1}
          minwidth="320px"
        >
          {(item: Sector.Data): JSX.Element => (
            <SectorsTableRowElement
              active={listPage.activeDetails.item?._id === item._id}
            >
              {item.name}
            </SectorsTableRowElement>
          )}
        </TableColumn>
      </Table>
    </SectorsPageElement>
  );
}
